import { IHelpState } from "./types";
import * as React from "react";
import { Button, Nav } from 'react-bootstrap'
import { connect } from 'react-redux';
import store from './store';

function mapStateToProps(state: IHelpState): IHelpState {
    return {
        HelpVisible: state.HelpVisible
    }
}

const NavigationContainer = connect(mapStateToProps)(Navigation);

function setHelpVisibility(visible: boolean) {
    store.dispatch({
        type: visible ? 'SHOW_HELP' : 'HIDE_HELP'
    });
}

function getHelpButton(prop: IHelpState) {
    if (prop.HelpVisible) {
        return (<Button variant="dark" onClick={() => setHelpVisibility(false) }>Close Help</Button>);
    } else {
        return (<Button variant="dark" onClick={() => setHelpVisibility(true) }>Help</Button>);
    }
}

export function Navigation (prop: IHelpState): JSX.Element {
    return (<Nav className="navbar navbar-dark bg-dark">
    <a className="navbar-brand text-light" href="#">List Loop Replacer</a>
    { getHelpButton(prop) }
</Nav>);
}

export class NavigationComponent extends React.Component {
    render() {
        return (<NavigationContainer />);
    }
}
