import * as React from 'react'
import { Row, Form, Button, Col } from 'react-bootstrap'

const dataHelpText = "List of strings or string-tuples, separated by ; or tab-char.";
const templateHelpText = "Template to be applied to every line of data entered above. Use {0}, {1}... as placeholder for every delimited field per row.";
const resultHelpText = "Applied template per row.";

export class ListLoopReplacerComponent extends React.Component<LlrProps, LlrState> {

    constructor(props: LlrProps) {
        super(props);

        this.state = {
            data: "Item-1;Col-1\nItem-2;Col-2\nItem-3;Col-3",
            template: "Use {0} for your service at {1} column",
            result: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <Form className="mt-3" onSubmit={this.handleSubmit}>
                <Row>
                    <Col className="mr-2">
                        <Form.Group controlId="data">
                            <Form.Label>Rows of data:</Form.Label>
                            <Form.Control as="textarea" id="dataInput" className="code" rows="10" placeholder="Enter your data here..." value={this.state.data} onChange={this.handleChange} />
                            <Form.Text className="text-muted">{dataHelpText}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="template">
                            <Form.Label>Template:</Form.Label>
                            <Form.Control as="textarea" id="templateInput" className="code" rows="10" placeholder="Enter your template here..." value={this.state.template} onChange={this.handleChange} />
                            <Form.Text className="text-muted">{templateHelpText}</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="result">
                            <Form.Label>Result:</Form.Label>
                            <Form.Control as="textarea" className="code" rows="24" value={this.state.result} readonly="true" />
                            <Form.Text className="text-muted">{resultHelpText}</Form.Text>
                        </Form.Group>
                        <Button variant="primary mb-3" type="submit">Apply Template</Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (event.target.id === "dataInput") {
            this.setState(
                {
                    data: event.target.value ?? ''
                });
        } else {
            this.setState(
                {
                    template: event.target.value ?? ''
                });
        }

    }

    handleSubmit(event: React.ChangeEvent<HTMLButtonElement>) {
        this.setState({
            result: processAllRows(this.state.data, this.state.template)
        });
        event.preventDefault();
    }
}

interface LlrProps {

}

interface LlrState {
    data: string,
    template: string,
    result: string
}

function processAllRows(data: string, template: string): string {
    const rows = data.split("\n");
    let result: string[] = [];
    for (let i = 0; i < rows.length; i++) {
        let lineResult = processTemplate(rows[i], template);
        result.push(lineResult);
    }
    return result.join("\n");
}

function processTemplate(row: string, template: string): string {
    const splitRegex = new RegExp("[\\t;]");
    const fields = row.split(splitRegex);

    var result = template;
    for (let i = 0; i < fields.length; i++) {
        result = result.replaceAll('{' + i + '}', fields[i]);
    }

    return result;
}