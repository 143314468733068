import { IHelpState } from "./types";
import * as React from "react";
import { Toast } from "react-bootstrap";
import { connect } from 'react-redux';
import store from './store';

function mapStateToProps(state: IHelpState): IHelpState {
    return {
        HelpVisible: state.HelpVisible
    }
}

function setHelpVisibility(visible: boolean) {
    store.dispatch({
        type: visible ? 'SHOW_HELP' : 'HIDE_HELP'
    });
}

const placeholdersample = '{0}, {1}, {2}...';

export function Help(prop: IHelpState): JSX.Element {
    return (
        <Toast className="mt-3" show={prop.HelpVisible} onClose={() => setHelpVisibility(false)}>
            <Toast.Header>
                <svg viewBox="0 0 34 34" width="20" height="20" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
                    <rect ry="2" y="2" x="0" height="30" width="30" fill="#007bff" stroke-linecap="round" id="rect1" />
                    <text font-weight="bold" font-family="sans-serif" fill="white" font-size="30px" transform="translate(6,28)">?</text>
                </svg>
                <strong className="ml-2 mr-auto">List Loop Replacer Help</strong>
            </Toast.Header>
            <Toast.Body>
                <p>This is a little tool that helps you filling in data from any CSV or TSV data source into a template for each row.</p>
                <p>The result can then be used in very different contexts.</p>
                <p>Rows have to be a list of strings, spearated by newline. Items per line have to be separated by either tab '\t' or semicolon ';'.</p>
                <p>The data template can be any text containing placeholder in the text. Use numbers in curly braces starting with 0. You can also reference the same item multiple times. [{placeholdersample}]</p>
            <br />
                <a href="https://github.com/thomas-mutter/mutter-list-loop-replacer">Project site on github</a>
            </Toast.Body>
        </Toast>
    );
}

const HelpContainer = connect(mapStateToProps)(Help);

export class HelpComponent extends React.Component {
    render() {
        return (<HelpContainer />);
    }
}
