import * as ReactDOM from 'react-dom';
import * as React from "react";
import { Provider } from 'react-redux'
import { Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import { ListLoopReplacerComponent } from './list-loop-replacer';
import { NavigationComponent } from './navigation';
import { HelpComponent } from './help';
import store from './store';

ReactDOM.render(
    <Provider store={store}>
        <NavigationComponent />
        <Container>
            <HelpComponent />
            <ListLoopReplacerComponent />
        </Container>
    </Provider>
    , document.getElementById('root'));
