import { createStore, Action } from 'redux';
import { IHelpState } from "./types";

const initialState: IHelpState = {
    HelpVisible: false
}

const reducer = (state = initialState, action: Action) => {
    if (action.type === 'SHOW_HELP') {
        return Object.assign({}, state, { HelpVisible: true })
    }

    if (action.type === 'HIDE_HELP') {
        return Object.assign({}, state, { HelpVisible: false })
    }

    return state
}

const store = createStore(reducer);

export default store;
